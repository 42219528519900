import { EditButton, InerContainer, ProfileRow } from "pages/Profile/styles";
import OutcomeIcon from "assets/icons/Complications";
import UserIcon from "assets/icons/User";
import Hospital from "assets/icons/Hospital";
import OCaseFields from "assets/icons/OperationCaseFields";
import Pgy from "assets/icons/SetIcon";
import { useReducer } from "react";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import Hospitals from "pages/Profile/components/Hospitals";
import PathIcon from "assets/icons/Pathology";
import PathOutcomes from "pages/Profile/components/PathOutcomes";
import ComOutcomes from "pages/Profile/components/ComOutcomes";
import ProfileUserType from "pages/Profile/components/ProfileUserType";
import OpCaseFields from "pages/Profile/components/OpCaseFields";
import SetUrgency from "pages/Profile/components/SetUrgency";
import SetPgy from "pages/Profile/components/SetPgy";
import Supervisors from "pages/Profile/components/Supervisors";
import Rotations from "pages/Profile/components/Rotations";
import SET from "pages/Profile/components/Set";
// import SurgicalOutcome from "pages/Profile/components/SurgicalOutcome";
import Rotation from "assets/icons/Rotation";
import { SettingsArrayType } from "interfaces";

interface IProfileSecondaryFields {
  dataSettingsProfile: SettingsArrayType;
  loading: boolean;
  settingsProfile: () => {};
}

function ProfileMainFields({
  dataSettingsProfile,
  settingsProfile,
}: IProfileSecondaryFields) {
  const [showHospital, toggleHospital] = useReducer(
    (showHospital) => !showHospital,
    false
  );
  const [showRotations, toggleRotations] = useReducer(
    (showRotations) => !showRotations,
    false
  );
  const [showUrgency, toggleUrgency] = useReducer(
    (showUrgency) => !showUrgency,
    false
  );
  const [showPgy, toggleshowPgy] = useReducer((showPgy) => !showPgy, false);
  const [showPathOutcome, togglePathOutcome] = useReducer(
    (showPathOutcome) => !showPathOutcome,
    false
  );
  const [showComOutcome, toggleComOutcome] = useReducer(
    (showComOutcome) => !showComOutcome,
    false
  );
  const [showSupervisor, toggleSupervisor] = useReducer(
    (showSupervisor) => !showSupervisor,
    false
  );
  const [showUserType, toggleUserType] = useReducer(
    (showUserType) => !showUserType,
    false
  );
  const [showSet, toggleSet] = useReducer((showSet) => !showSet, false);
  const [showOpCaseFields, toggleOpCaseFields] = useReducer(
    (showOpCaseFields) => !showOpCaseFields,
    false
  );
  // const [surgicalOutcome, toggleSurgicalOutcome] = useReducer(
  //   (surgicalOutcome) => !surgicalOutcome,
  //   false
  // );

  return (
    <>
      <ProfileRow>
        <>
          <InerContainer>
            <EditButton>
              <Button onClick={togglePathOutcome} text="Pathology outcomes" />
            </EditButton>
            <EditButton>
              <Button onClick={toggleComOutcome} text="Complication outcomes" />
            </EditButton>
            <EditButton>
              <Button
                as="button"
                onClick={toggleSupervisor}
                text="Supervisors"
              />
            </EditButton>
            <EditButton>
              <Button onClick={toggleSet} text="SET" />
            </EditButton>
            <EditButton>
              <Button onClick={toggleHospital} text="Hospitals" />
            </EditButton>
            <EditButton>
              <Button onClick={toggleUserType} text="User type" />
            </EditButton>
            <EditButton>
              <Button
                text="Operation case fields"
                onClick={toggleOpCaseFields}
              />
            </EditButton>
            <EditButton>
              <Button onClick={toggleshowPgy} text="PGY" />
            </EditButton>
            <EditButton>
              <Button onClick={toggleUrgency} text="Urgency" />
            </EditButton>
            <EditButton>
              <Button onClick={toggleRotations} text="Rotation" />
            </EditButton>
            {/* <EditButton>
              <Button onClick={toggleSurgicalOutcome} text="Surgical outcome" />
            </EditButton> */}
          </InerContainer>
        </>
      </ProfileRow>
      <Hospitals
        icon={<Hospital color={baseTheme.colors.main} width={32} height={32} />}
        label={"Add new Hospital"}
        title={"Hospitals"}
        isOpen={showHospital}
        onRequestClose={toggleHospital}
        type={"hospital"}
        loading={false}
        titleList="Hospitals list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <PathOutcomes
        icon={<PathIcon color={baseTheme.colors.main} width={32} height={32} />}
        label={"Add new pathology outcome"}
        title={"Pathology outcomes"}
        isOpen={showPathOutcome}
        onRequestClose={togglePathOutcome}
        type={"pathology-outcome"}
        loading={false}
        titleList="Pathology outcomes list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <Supervisors
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        label={"Add new Supervisor"}
        title={"Supervisors"}
        isOpen={showSupervisor}
        onRequestClose={toggleSupervisor}
        type={"supervisor"}
        loading={false}
        titleList="Supervisors list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <ComOutcomes
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        label={"Add new complication outcome"}
        title={"Complication outcomes"}
        isOpen={showComOutcome}
        onRequestClose={toggleComOutcome}
        type={"complication-outcome"}
        loading={false}
        titleList="Complication outcoms list"
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
      />
      <ProfileUserType
        icon={<UserIcon color={baseTheme.colors.main} width={32} height={32} />}
        title={"Select user type"}
        isOpen={showUserType}
        settingsProfile={settingsProfile}
        onRequestClose={toggleUserType}
      />
      <OpCaseFields
        icon={
          <OCaseFields color={baseTheme.colors.main} width={32} height={32} />
        }
        title={"Operation case fields"}
        isOpen={showOpCaseFields}
        onRequestClose={toggleOpCaseFields}
      />
      <SetUrgency
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        title={"Select urgency"}
        isOpen={showUrgency}
        data={dataSettingsProfile}
        settingsProfile={settingsProfile}
        onRequestClose={toggleUrgency}
        loading={false}
      />
      <SetPgy
        icon={<Pgy color={baseTheme.colors.main} width={32} height={32} />}
        title={""}
        isOpen={showPgy}
        data={dataSettingsProfile}
        onRequestClose={toggleshowPgy}
        loading={false}
        settingsProfile={settingsProfile}
        label={"Enter PGY"}
      />
      <Rotations
        title={""}
        data={dataSettingsProfile}
        isOpen={showRotations}
        onRequestClose={toggleRotations}
        icon={<Rotation color={baseTheme.colors.main} width={32} height={32} />}
      />
      <SET
        title={""}
        data={dataSettingsProfile}
        isOpen={showSet}
        onRequestClose={toggleSet}
        icon={<Pgy color={baseTheme.colors.main} width={32} height={32} />}
      />
      {/* <SurgicalOutcome
        icon={
          <OutcomeIcon color={baseTheme.colors.main} width={32} height={32} />
        }
        isOpen={surgicalOutcome}
        onRequestClose={toggleSurgicalOutcome}
      /> */}
    </>
  );
}

export default ProfileMainFields;
