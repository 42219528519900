import { Tooltip as ReactTooltip } from "react-tooltip";

interface ITooltipComponent {
  id: string;
  content: string;
}

export default function TooltipComponent({ id, content }: ITooltipComponent) {
  return <ReactTooltip id={id} place="bottom" content={content} />;
}
