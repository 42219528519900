import React from "react";
import styled from "styled-components";

const SectionStyled = styled.section`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 900px) {
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
`;

const StyledFormRow = styled.div`
  max-width: 50%;
  margin: 0 auto;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

const StyledRow = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
  width: 100vw;
  height: 100vh;
  @media (max-width: 900px) {
    display: none;
  }
`;
const StyledImage = styled.div`
  background: linear-gradient(230deg, #199a8e, #000);
  background-size: 300% 300%;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IAuthenticationLayout {
  children: React.ReactNode;
}
function AuthenticationLayout({ children }: IAuthenticationLayout) {
  return (
    <>
      <SectionStyled>
        <StyledFormRow>{children}</StyledFormRow>
        <StyledRow>
          <StyledImage />
        </StyledRow>
      </SectionStyled>
    </>
  );
}

export default AuthenticationLayout;
