import styled from "styled-components";
import { baseTheme } from "styles/theme";

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  padding: 10px;
  border-radius:10px;
  height: 100%;
  position: relative;
  background-color: ${baseTheme.colors.white};
  padding-bottom: 10px;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const RowPayment = styled.div`
display: flex;
align-items: center;
flex-direction: row;
`
export const ProfileRow = styled.div`
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  /* border-left: 2px solid ${baseTheme.colors.gray}; */
  @media (max-width: 900px) {
    width: 100%;
    align-items: center;
  }
`;
export const InputsOutside = styled.div`
  width: 60%;
  padding: 60px 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const InputInside = styled.div`
  width: 40%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const EditButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 300px;
  margin-top: 16px;
  @media (max-width: 900px) {
    width: 280px;
  }
`;
export const InerContainer =styled.div`

align-items: center;
justify-content: center;
flex-direction: column;
display: flex;

`
export const Row = styled.span`
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: center;
  margin-top: 15px;
  @media (max-width: 900px) {
    width: 60%;
    flex-direction: column;
  }
`;
export const EditProfileButton = styled.div``;
export const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin: 20px 0;
  }
`;