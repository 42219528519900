// components/Meta.js
import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <div>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="stylesheet" href="/css/react-phone-number-input/style.css" />
        <style>{`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
          
        `}</style>
      </Helmet>
    </div>
  );
};

export default Meta;
