import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

interface ISkeletonComponentProps {
  height?: number | string;
  width?: number | string;
  count?: number;
  style?: React.CSSProperties;
}

function SkeletonComponent({
  count = 1,
  height = 15,
  style,
  width = 70,
}: ISkeletonComponentProps) {
  return <Skeleton style={style} count={count} height={height} width={width} />;
}

export default SkeletonComponent;
