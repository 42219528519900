import Modal from "components/Modal/Modal";
import UpdateModalInfo from "components/Modal/UpdateProfileModal";
import { SettingsArrayType } from "interfaces";
import { ReactNode } from "react";

interface ISupervisors {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
  type: string;
  settingsProfile?: () => void;
  label: string;
  titleList?: string;
  loading: boolean;
  data: SettingsArrayType;
  icon?: ReactNode;
}

export default function Supervisors({
  isOpen,
  onRequestClose,
  type,
  settingsProfile,
  titleList,
  loading,
  label,
  data,
  icon,
  title,
}: ISupervisors) {
  return (
    <Modal
      isOpen={isOpen}
      icon={icon}
      onRequestClose={onRequestClose}
      children={
        <UpdateModalInfo
          settingsProfile={settingsProfile!}
          type={type}
          titleList={titleList}
          isLoading={loading}
          data={data}
          label={label}
        />
      }
      title={title}
    ></Modal>
  );
}
