import ButtonBack from "components/Buttons/BackButton";
import ReviewCase from "components/ReviewCase";
import MainLayout from "layouts/MainLayout";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  position: relative;
  background-color: ${baseTheme.colors.white};
  padding-bottom: 10px;
  justify-content: flex-start;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

function ReviewCasePage() {
  return (
    <MainLayout
      children={
        <Wrapper>
          <ButtonBack />
          <ReviewCase />
        </Wrapper>
      }
    />
  );
}

export default ReviewCasePage;
