import styled from 'styled-components';
import { baseTheme } from './theme';

export const StyledContainer = styled.div`
  display: flex;
  width: 1100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 0 100px;
  }

  @media (max-width: 1100px) {
    width: auto;
  }

  @media (max-width: 600px) {
    padding: 0 20px;
  }
`;
export const StyledH3 = styled.h3`
  margin-bottom: 2rem;
  line-height: 1.1;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 800;
  /* text-shadow: 1px 1px 2px rgba(40, 40, 40, 0.2); */
  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
  @media (min-width: 2500px) {
    font-size: 2rem;
  }
`;

export const StyledH4 = styled.h3`
  line-height: 1;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 700;
  /* text-shadow: 1px 1px 2px rgba(40, 40, 40, 0.2); */
  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;
export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const TitleSection = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;