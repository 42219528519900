import DataComponent from "./DataComponent";

function Complications() {
  return (
    <DataComponent
      uri={"operation-case/complication?search="}
      title={"Complications"}
      activeTab={"Complications"}
    />
  );
}

export default Complications;
