import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { baseTheme } from "styles/theme";

interface ISpinner {
  size?: number;
  color?: string;
}

export default function Spinner({
  size = 30,
  color = baseTheme.colors.main,
}: ISpinner) {
  return (
    <PuffLoader
      color={color}
      loading={true}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
      speedMultiplier={0.9}
    />
  );
}
