import React, { useState } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const StyledInputWrap = styled.div`
  margin-bottom: 1em;
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
`;

export interface Option {
  label: string;
  value: number;
}

interface SelectProps {
  label?: string;
  options: Option[];
  onChange: (value: number) => void;
  isOpDetails?: boolean;
}

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface IStyledCustomSelect {
  isOpDetails?: boolean;
}
const StyledCustomSelect = styled.div<IStyledCustomSelect>`
  position: relative;
  width: 100%;
  padding: 12px;
  height: 50px;
  line-height: 25px;
  font-size: 14px;
  border-bottom: 1px solid
    ${(props) => (props.isOpDetails ? baseTheme.colors.main : "none")};
  border: 1px solid
    ${(props) => (props.isOpDetails ? "none" : baseTheme.colors.gray)};
  border-radius: ${(props) => (props.isOpDetails ? 0 : "49px")};
  background-color: ${(props) =>
    props.isOpDetails ? "transparent" : baseTheme.colors.gray};
  @media (min-width: 1921px) {
    font-size: 20px;
    height: 100%;
  }
`;

const StyledCustomOptions = styled.div`
  position: absolute;
  top: 95%;
  left: 0;
  width: 100%;
  font-size: 14px;
  background-color: ${baseTheme.colors.gray};
  border: 1px solid ${baseTheme.colors.main};
  border-radius: ${baseTheme.sizes.radius.input}px;
  z-index: 1;
  overflow-y: auto;
  max-height: 200px;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
  div {
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: ${baseTheme.colors.main};
      color: ${baseTheme.colors.gray};
    }
  }
`;

const Select: React.FC<SelectProps> = ({
  label,
  options,
  onChange,
  isOpDetails,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  return (
    <StyledInputWrap>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelectWrapper>
        <StyledCustomSelect
          isOpDetails={isOpDetails}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption ? (
            selectedOption.label.length > 30 ? (
              `${selectedOption.label.slice(0, 25)}...`
            ) : (
              selectedOption.label
            )
          ) : (
            <span style={{ color: baseTheme.colors.black }}>Select ...</span>
          )}
        </StyledCustomSelect>

        {isOpen && (
          <StyledCustomOptions>
            {options.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  onChange(option.value);
                  setSelectedOption(option);
                  setIsOpen(false);
                }}
              >
                {option.label}
              </div>
            ))}
          </StyledCustomOptions>
        )}
      </StyledSelectWrapper>
    </StyledInputWrap>
  );
};

export default Select;
