import { useState, ChangeEvent, FocusEvent } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  border-bottom: 1px solid ${baseTheme.colors.main};
  width: 100%;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;

const Suggestions = styled.div`
  position: absolute;
  background: white;
  border: 1px solid ${baseTheme.colors.gray};
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
`;

const SuggestionItem = styled.div`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: ${baseTheme.colors.gray};
  }
`;

interface AutoCompleteInputProps {
  value: string;
  allSupervisors: any;
  onChange: (value: string) => void;
}

export interface Supervisor {
  id: number;
  name: string;
  last_used: string;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  value,
  allSupervisors,
  onChange,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSuggestionClick = (suggestion: string) => {
    onChange(suggestion);
    setShowSuggestions(false);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => setShowSuggestions(false), 100);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);

    setShowSuggestions(true);
  };

  const filteredOptions = allSupervisors.filter((option: Supervisor) =>
    option.name.toLowerCase().includes(value.toLowerCase())
  );

  return (
    <Wrapper>
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setShowSuggestions(true)}
      />
      {showSuggestions && (
        <Suggestions>
          {filteredOptions.map((option: Supervisor) => (
            <SuggestionItem
              key={option.id}
              onMouseDown={() => handleSuggestionClick(option.name)}
            >
              {option.name}
            </SuggestionItem>
          ))}
        </Suggestions>
      )}
    </Wrapper>
  );
};

export default AutoCompleteInput;
