import React, { useState } from "react";
import AuthenticationLayout from "layouts/AuthLayout";
import UserType from "components/Auth/UserType";
import Speciality from "components/Auth/Speciality";
import { useAxios } from "hooks/useAxios";
import { useNavigate } from "react-router-dom";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import { styled } from "styled-components";
import { getToken, getUserRole, saveUserType } from "helpers";
import userTypeButtons from "data/userTypeButtons";

const UserSettingsContainer = styled.div`
  max-width: 300px;
`;

function UserSettingsPage() {
  const token = getToken();
  const navigate = useNavigate();
  const { request } = useAxios();
  const [currentStep, setCurrentStep] = useState(1);
  const [userType, setUserType] = useState("");
  const [item, setItem] = useState<string | number | undefined>();
  async function SaveUserType(value: string) {
    setUserType(value);
    setCurrentStep(2);
  }
  const statusRole = getUserRole();
  async function SaveUserSetting() {
    const res = await request(
      "profile/type-speciality",
      "POST",
      {
        type: userType,
        speciality: "deez nuts",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (res.message === "Updated") {
      request(
        "profile/presets",
        "POST",
        {
          presets: {
            rotation: item,
          },
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
    }
    saveUserType(userType);
    if (statusRole === "admin" || statusRole === "superadmin") {
      navigate("/users");
    } else {
      navigate("/case-logs");
    }
  }

  return (
    <AuthenticationLayout>
      <UserSettingsContainer>
        {currentStep === 1 && (
          <UserType
            buttons={userTypeButtons}
            title={"I am a:"}
            onPress={SaveUserType}
          />
        )}
        {currentStep === 2 && (
          <>
            <Speciality setItem={setItem} />
            <Button
              color={baseTheme.colors.white}
              onClick={SaveUserSetting}
              text="Save"
            />
          </>
        )}
      </UserSettingsContainer>
    </AuthenticationLayout>
  );
}

export default UserSettingsPage;
