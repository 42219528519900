import ButtonBack from "components/Buttons/BackButton";
import DataComponent from "components/DataComponent";
import MainLayout from "layouts/MainLayout";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;
function ReviewUserLogsPage() {
  const userReviewLogId = localStorage.getItem("userReviewLogId");

  return (
    <MainLayout>
      <Wrapper>
        <ButtonBack />

        <DataComponent
          uri={`admin/user/${userReviewLogId}/operation-case`}
          title={"Review user logs"}
          activeTab={""}
        />
      </Wrapper>
    </MainLayout>
  );
}

export default ReviewUserLogsPage;
