import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getToken, getUserRole } from "helpers";
import useAuthStore from "store/authStore";
import Spinner from "components/Spinners/ButtonSpinner";

const PublicRoute = ({
  children,
  restricted,
}: {
  children: JSX.Element;
  restricted?: boolean;
}) => {
  const setAuthenticated = useAuthStore.getState().setAuthenticated;
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const token = getToken();
  const userRole: any = getUserRole();
  useEffect(() => {
    setLoading(true);
    if (token) {
      setAuthenticated(true);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [token]);

  if (loading) {
    return <Spinner />;
  }

  if (isAuthenticated && restricted) {
    if (userRole === "superadmin" || userRole === "admin") {
      return <Navigate to="/users" />;
    } else {
      return <Navigate to="/case-logs" />;
    }
  }

  return children;
};

export default PublicRoute;
