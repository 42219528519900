import {
  IUserLSorage,
  getFromLocalStorageUser,
  getToken,
  getUserType,
} from "helpers";
import { useAxios } from "hooks/useAxios";
import useDebounce from "hooks/useDebounce";
import { OperationCase } from "interfaces";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import Search from "components/Search";
import DropdownFormats from "components/DropdownFormats";
import Table from "components/DataTable";
import { useNavigate } from "react-router-dom";
import ModalPathology from "./Modal/ModalPathology";
import DatePickerComponent from "./DatePicker";
import { CurrentPageData } from "./UserTable";
import Pagination from "./Pagination";
import Button from "./Buttons/Button";
interface IDataComponent {
  uri: string;
  title: string;
  activeTab: string;
  hideEmptyColumns?: boolean;
  pathButtonMode?: string;
  pathButtonTitle?: string;
}
export const Column = styled.div`
  display: flex;
  padding: 10px 0 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    justify-content: flex-start;
    padding-left: 10px;
  }
`;
export const DropdownFormatWrap = styled.div`
  position: absolute;
  right: 20px;
`;
export const TitleTab = styled.div`
  font-size: 1.4rem;
  line-height: 36px;
  font-weight: 700;
  color: ${baseTheme.colors.textTable};
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
  @media (min-width: 1921px) {
    font-size: 32px;
  }
`;
export const Container = styled.div`
  width: 100%;
  align-items: center;
  padding: 20px 0px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`;

export const SearchWrap = styled.div`
  width: 100%;
  @media (min-width: 1200px) {
    width: 50%;
  }
`;

export const CombinedComponentsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 1200px) {
    width: 50%;
    padding-top: 0;
    justify-content: flex-end;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DatePickerComponentWrap = styled.div`
  width: calc(50%);
  @media (min-width: 1200px) {
    width: calc(65%);
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: calc(70%);
    margin: 0;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ExportButtonWrap = styled.div`
  width: 200px;
  @media (min-width: 1200px) {
    width: 35%;
  }
  @media (max-width: 768px) {
    width: 150px;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 20px;
  }
`;

export const TableContainer = styled.div``;
export const Wrapper = styled.div`
  z-index: 0;
  position: relative;
  background-color: rgb(242, 242, 242);
`;
export const ContentContainer = styled.div`
  display: flex;
  padding: 0 10px 10px;
  flex-direction: column;
  border-radius: 10px;
  height: 97vh;
  background-color: ${baseTheme.colors.white};
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 991px) {
    height: 89vh;
  }
`;
const ButtonWrapper = styled.div`
  align-self: flex-end;
  width: 200px;
  @media (min-width: 1921px) {
    width: 250px;
  }
`;
function DataComponent({
  uri,
  title,
  activeTab,
  pathButtonTitle,
  pathButtonMode,
}: IDataComponent) {
  const navigate = useNavigate();
  const CloseCheckModal = () => {
    setIsModalOpen(false);
  };
  const user: IUserLSorage = getFromLocalStorageUser("user");
  const { role } = user || {};
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userType = getUserType();
  const [selectedItem, setSelectedItem] = useState<OperationCase>();
  const [localSearchWord, setLocalSearchWord] = useState<string>("");
  const debouncedLocalSearchWord = useDebounce(localSearchWord, 400);
  const [meta, setMeta] = useState<CurrentPageData>();
  const goToReivew = (rowData: OperationCase) => {
    localStorage.setItem("reviewId", rowData.id.toString());
    navigate("/review");
  };

  const token = getToken();
  const { request, loading } = useAxios();
  const openCheckModal = (rowData: OperationCase) => {
    setIsModalOpen(true);
    setSelectedItem(rowData);
  };

  useEffect(() => {
    getData(uri);
    // eslint-disable-next-line
  }, [debouncedLocalSearchWord, startDate, endDate]);
  const columns = [
    {
      Header: "Operation Date",
      accessor: (row: OperationCase) => row.date,
    },
    {
      Header: "Full Name",
      accessor: (row: OperationCase) => {
        const patient = row.patient;
        if (patient) {
          return `${patient.first_name} ${patient.last_name}`;
        }
        return "N/A";
      },
    },
    { Header: "UR number", accessor: "patient.ur_number" },
    { Header: "Operation name", accessor: "operation_name" },
  ];
  if (userType === "consultant") {
    columns.push({
      Header: "Hospital",
      accessor: (row: OperationCase) => {
        const hospital = row.hospital;
        if (hospital) {
          return hospital.name;
        }
        return "N/A";
      },
    });
  }
  if (userType === "set-trainee" || userType === "unaccredited-trainee") {
    columns.push({
      Header: "Supervisor",
      accessor: (row: OperationCase) => {
        const supervisor = row.supervisor;
        if (supervisor) {
          return supervisor.name;
        }
        return "N/A";
      },
    });
  }
  if (activeTab === "Pathologies") {
    columns.push({ Header: "Path check", accessor: "path_check" });
  }
  const getData = async (apiUrl: string) => {
    const searchWord = debouncedLocalSearchWord ? debouncedLocalSearchWord : "";
    const res = await request(
      `${apiUrl}`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      { search: searchWord, date_from: startDate, date_to: endDate }
    );
    setMeta(res?.meta || {});
    setTableData(res?.data || []);
  };

  const unCheckPathology = async () => {
    CloseCheckModal();
    try {
      const res = await request(
        `operation-case/pathology/${selectedItem!.id}/uncheck`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (res) {
        try {
          const res = await request(
            "operation-case/pathology/checked",
            "GET",
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          setTableData(res?.data || []);
        } catch (e) {}
      }
    } catch (e) {}
  };
  const checkPathology = async () => {
    CloseCheckModal();
    try {
      const res = await request(
        `operation-case/pathology/${selectedItem!.id}/check`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (res) {
        try {
          const res = await request(
            "operation-case/pathology/unchecked",
            "GET",
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          setTableData(res?.data || []);
        } catch (e) {}
      }
    } catch (e) {}
  };
  const switchPathology = async () => {
    if (selectedItem!.path_check) {
      unCheckPathology();
    } else {
      checkPathology();
    }
  };

  return (
    <>
      <Wrapper>
        <ContentContainer>
          <Column>
            <TitleTab>{title}</TitleTab>
          </Column>
          <Container>
            <SearchWrap>
              <Search
                searchWord={localSearchWord}
                setSearchWord={setLocalSearchWord}
              />
            </SearchWrap>
            {role === "user" && (
              <CombinedComponentsWrap>
                <DatePickerComponentWrap>
                  <DatePickerComponent
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                  />
                </DatePickerComponentWrap>
                <ExportButtonWrap>
                  <DropdownFormats
                    startDate={startDate}
                    endDate={endDate}
                    activeTab={activeTab}
                    word={debouncedLocalSearchWord}
                  />
                </ExportButtonWrap>
              </CombinedComponentsWrap>
            )}
          </Container>

          <Table
            openCheckModal={openCheckModal}
            onRowClick={goToReivew}
            columns={columns}
            loading={loading}
            data={tableData}
            activeTab={activeTab}
          />
          <>
            {meta && meta.total > 15 && (
              <Pagination
                setMeta={setMeta}
                setUsersData={setTableData}
                links={meta!.links}
                currentPage={meta!.current_page}
                totalPages={meta!.last_page}
              />
            )}
            {pathButtonTitle && pathButtonMode && (
              <ButtonWrapper>
                <Button
                  fontWeight={500}
                  fontSize="14px"
                  bg={baseTheme.colors.main}
                  as="a"
                  text={pathButtonTitle}
                  mode={pathButtonMode}
                />
              </ButtonWrapper>
            )}
          </>
        </ContentContainer>
      </Wrapper>
      <ModalPathology
        isOpen={isModalOpen}
        checkPathology={switchPathology}
        closeModal={CloseCheckModal}
        addOutcome={goToReivew}
        item={selectedItem!}
      />
    </>
  );
}

export default DataComponent;
