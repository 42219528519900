/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ChangeEvent, useMemo, useState } from "react";
import { StyledH3 } from "styles/components";
import {
  StyleButtonWrapper,
  StyledDflex,
  StyledSpanColor,
  StyledSpanText,
  StyledText,
  Form,
  ContainerInput,
  StyledRow,
  IconWrap,
} from "./styles";
import Input from "components/Inputs/Input";
import { baseTheme } from "styles/theme";
import ToggleSwitch from "components/Buttons/ToggleSwitch";
import Button from "components/Buttons/Button";
import { IAuthForm } from "interfaces";
import { useAxios } from "hooks/useAxios";
import useSignUpStore from "store/signUpData";
import { Link, useNavigate } from "react-router-dom";
import PhoneField from "components/PhoneInput/PhoneInput";
import Toast from "components/Toast";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { saveUserRole } from "helpers";

function SignUpForm() {
  const navigate = useNavigate();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const { request, error, loading, clearError } = useAxios();
  const { SignUpData } = useSignUpStore();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [formData, setFormData] = useState<IAuthForm>({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [formDataErrors, setFormDataErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [phone, setPhone] = useState<string>("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleAgreementChange = () => {
    setAgreedToTerms((prevAgreedToTerms) => !prevAgreedToTerms);
  };
  const buttonDisabled = useMemo(() => {
    return (
      !agreedToTerms ||
      !formData.first_name ||
      !formData.last_name ||
      !formData.confirm_password ||
      !formData.password ||
      !formData.email ||
      !phone ||
      formDataErrors.first_name !== "" ||
      formDataErrors.last_name !== "" ||
      formDataErrors.email !== "" ||
      formDataErrors.password !== "" ||
      formDataErrors.confirm_password !== ""
    );
  }, [formData, formDataErrors, phone, agreedToTerms]);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "first_name" || name === "last_name") {
      if (!value.trim()) {
        error = "This field is required";
      }
      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        [name]: error,
      }));
    }
    if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Invalid email address";
      }
      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        email: error,
      }));
    }
    if (name === "password") {
      if (value.length < 8) {
        error = "Password must contain at least 8 characters";
      } else if (!/\d/.test(value)) {
        error = "Password must contain one digit";
      } else if (!/[A-Z]/.test(value)) {
        error = "Password must contain at least one uppercase letter";
      } else if (!/[@#$%^&*()_+!]/.test(value)) {
        error = "Password must contain one special character";
      }

      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        password: error,
      }));
    }

    if (name === "confirm_password") {
      const passwordValue = formData.password;
      if (value !== passwordValue) {
        error = "Passwords do not match";
      }

      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        confirm_password: error,
      }));
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const checkPhone = async () => {
    try {
      const res = await request("auth/check-phone", "POST", {
        phone: phone,
      });
      if (res.token) {
        saveUserRole("user");
        SignUpData({
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          password: formData.password,
          token: res.token,
          phoneSignUp: phone,
        });
        navigate("/verification");
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clearError();
    setErrorPassword("");
    if (formData.password !== formData.confirm_password) {
      setErrorPassword("Password and Confirm Password do not match");
      return;
    }

    try {
      const res = await request("auth/register/check-email", "POST", {
        email: formData.email,
      });
      if (res) {
        checkPhone();
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };
  return (
    <>
      <StyledH3>Sign Up</StyledH3>
      <Form onSubmit={handleSubmit}>
        <Input
          placeholder="First name"
          type="text"
          error={formDataErrors.first_name}
          name="first_name"
          required={true}
          maxLength={40}
          value={formData.first_name}
          onChange={handleInputChange}
        />
        <Input
          placeholder="Last name"
          error={formDataErrors.last_name}
          type="text"
          name="last_name"
          required={true}
          maxLength={40}
          value={formData.last_name}
          onChange={handleInputChange}
        />
        <Input
          placeholder="Email"
          type="email"
          name="email"
          error={formDataErrors.email}
          autoComplete="email"
          required={true}
          value={formData.email}
          onChange={handleInputChange}
        />
        <Input
          error={formDataErrors.password}
          name="password"
          type={showPassword ? "text" : "password"}
          required={true}
          autoComplete="current-password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          icon={
            <IconWrap onClick={togglePasswordVisibility}>
              {!showPassword ? (
                <EyeOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              )}
            </IconWrap>
          }
        />
        <Input
          error={formDataErrors.confirm_password}
          type={showConfirmPassword ? "text" : "password"}
          name="confirm_password"
          placeholder="Confirm password"
          autoComplete="current-password"
          value={formData.confirm_password}
          onChange={handleInputChange}
          icon={
            <IconWrap onClick={togglePasswordConfirmVisibility}>
              {!showConfirmPassword ? (
                <EyeOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              ) : (
                <EyeInvisibleOutlined
                  style={{ color: baseTheme.colors.textGray, fontSize: "18px" }}
                />
              )}
            </IconWrap>
          }
        />
        <ContainerInput>
          <PhoneField phone={phone} setPhone={setPhone} />
        </ContainerInput>

        <StyledDflex>
          <ToggleSwitch
            value={agreedToTerms}
            onChange={handleAgreementChange}
          />
          <StyledText>
            I agree to the Snap Log{" "}
            <Link target="_blank" to="/privacy-policy">
              {" "}
              <StyledSpanColor>Privacy Policy</StyledSpanColor>
            </Link>{" "}
            and{" "}
            <Link target="_blank" to="/terms-and-conditions">
              {" "}
              <StyledSpanColor>Terms and Conditions </StyledSpanColor>
            </Link>
          </StyledText>
        </StyledDflex>
        <StyleButtonWrapper>
          <Button
            disabled={buttonDisabled}
            loading={loading}
            text="Create Account"
          />
        </StyleButtonWrapper>
        <StyledRow>
          <StyledSpanText>or</StyledSpanText>
          <Button
            fontWeight={700}
            text="Login"
            color={baseTheme.colors.black}
            mode="/login"
            as="a"
          />
        </StyledRow>
      </Form>
      <Toast error={error || errorPassword} />
    </>
  );
}

export default SignUpForm;
