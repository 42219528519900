import { create } from 'zustand';

interface SignUpState {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  token: string;
  phoneSignUp: string;
  SignUpData: (data: {
    phoneSignUp: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    token: string;
  }) => void;
  clearSignUpState: () => void; // Метод для очистки состояния
}

const useSignUpStore = create<SignUpState>((set) => ({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  token: "",
  phoneSignUp: "",
  SignUpData: (data) => set(data),
  clearSignUpState: () =>
    set({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      token: "",
      phoneSignUp: "",
    }),
}));

export default useSignUpStore;
