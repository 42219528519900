import { IIcon } from "interfaces";

const PaperClip = ({ color, width = 18, height = 18 }: IIcon) => {
  return (
    <div style={{ width: width, height: height }}>
      <svg
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M289.129412 993.882353c-60.235294 0-120.470588-24.094118-162.635294-69.270588C84.329412 882.447059 60.235294 825.223529 60.235294 761.976471c0-63.247059 24.094118-120.470588 66.258824-165.647059l512-518.02353c66.258824-66.258824 168.658824-63.247059 240.941176 9.035294 69.270588 72.282353 75.294118 177.694118 9.035294 243.952942l-481.882353 484.894117c-39.152941 39.152941-102.4 39.152941-138.541176 0-18.070588-18.070588-30.117647-45.176471-30.117647-69.270588s9.035294-51.2 30.117647-69.270588l325.270588-328.282353 42.164706 42.164706-325.270588 325.270588c-6.023529 6.023529-12.047059 18.070588-12.047059 27.105882 0 12.047059 3.011765 21.082353 12.047059 27.105883 15.058824 15.058824 39.152941 15.058824 54.211764 0L843.294118 286.117647c42.164706-42.164706 39.152941-111.435294-9.035294-159.623529-45.176471-45.176471-111.435294-48.188235-153.6-6.02353l-512 518.02353c-30.117647 33.129412-48.188235 75.294118-48.188236 123.482353s18.070588 90.352941 51.2 123.482353c33.129412 33.129412 75.294118 51.2 120.470588 51.2s87.341176-18.070588 120.470589-51.2l512-518.02353 42.164706 42.164706L451.764706 927.623529c-42.164706 42.164706-99.388235 66.258824-162.635294 66.258824z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default PaperClip;
