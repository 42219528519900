import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getToken, getUserRole } from "helpers";
import useAuthStore from "store/authStore";
import { Role } from "components/Router";
import Spinner from "components/Spinners/ButtonSpinner";

const PrivateRoute = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: Array<Role>;
}) => {
  const location = useLocation();
  const setAuthenticated = useAuthStore.getState().setAuthenticated;
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const token = getToken();
  const userRole: any = getUserRole();

  useEffect(() => {
    setLoading(true);
    if (token) {
      setAuthenticated(true);
    }
    setLoading(false);
    // eslint-disable-next-line
  }, [token]);

  const userHasRequiredRole = userRole && roles.includes(userRole);

  if (loading) {
    return <Spinner />;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
