import React from "react";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import ResetPasswordForm from "./ResetPasswordForm";
import styled from "styled-components";

export const StyledAuthForm = styled.div`
  width: 400px;
  @media (min-width: 1921px) {
    width: 450px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }
`;
interface IAuthForm {
  mode: string;
}

function AuthForm({ mode }: IAuthForm) {
  let formComponent;

  switch (mode) {
    case "register":
      formComponent = <SignUpForm />;
      break;
    case "resetpsw":
      formComponent = <ResetPasswordForm />;
      break;
    case "login":
      formComponent = <LoginForm />;
      break;
  }

  return <StyledAuthForm>{formComponent}</StyledAuthForm>;
}

export default AuthForm;
