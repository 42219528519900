import React from "react";
import { baseTheme } from "styles/theme";
import styled from "styled-components";

const StyledToggleSwitchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInputToggle = styled.input`
  display: none;

  &:checked + label {
    background: ${baseTheme.colors.main};
    width: 2.25rem;
  }

  &:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
`;

const StyledLabelToggle = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  height: 1.25rem;
  width: 2.25rem;
  background: ${baseTheme.colors.gray};
  display: block;
  border-radius: 100px;
  position: relative;
  transition: 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    height: calc(1.25rem - 2px);
    width: calc(1.25rem - 2px);
    background: ${baseTheme.colors.white};
    border-radius: 90px;
    transition: 0.3s;
    border: 1px solid rgba(40, 40, 40, 0.3);
  }
`;

function ToggleSwitch({
  value,
  onChange,
}: {
  value: boolean;
  onChange: () => void;
}) {
  return (
    <StyledToggleSwitchWrap>
      <StyledInputToggle
        type="checkbox"
        id="switch"
        checked={value}
        onChange={onChange}
      />
      <StyledLabelToggle htmlFor="switch">Toggle</StyledLabelToggle>
    </StyledToggleSwitchWrap>
  );
}

export default ToggleSwitch;
