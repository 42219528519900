import Users from "components/Users";
import styled from "styled-components";

const UserManagmentWrap = styled.div`
  width: 100%;
`;

function UserManagment() {
  const columns = [
    { Header: "Identifier", accessor: "id" },
    { Header: "First Name", accessor: "first_name" },
    { Header: "Last Name", accessor: "last_name" },
    { Header: "Email Address", accessor: "email" },
    { Header: "Contact Number", accessor: "phone" },
    { Header: "Actions", accessor: "actions" },
  ];
  return (
    <UserManagmentWrap>
      <Users columns={columns} title="Users" actions />
    </UserManagmentWrap>
  );
}

export default UserManagment;
