import useAuthStore from "store/authStore";
import moment from "moment";
export function PhoneNumberMask(phoneNumber: string) {
  return phoneNumber.replace(/\d{3}$/, "***");
}

type Token = string | null;

export const saveToken = (token: Token): void => {
  localStorage.setItem("authToken", token || "");
};

export const getToken = (): Token => {
  return localStorage.getItem("authToken") || null;
};

export const removeToken = (): void => {
  localStorage.removeItem("authToken");
};

export const saveUserType = (userType: Token): void => {
  localStorage.setItem("userType", userType || "");
};



export const getUserType = (): Token => {
  return localStorage.getItem("userType") || null;
};

export const saveUserRole = (userRole: string): void => {
  localStorage.setItem("userRole", userRole);
};

export const getUserRole = (): Token => {
  return localStorage.getItem("userRole") || "";
};

export const saveStatusMember = (statusMember: string): void => {
  localStorage.setItem("statusMember", statusMember);
};

export const getStatusMember = () => {
  return localStorage.getItem("statusMember");
};

export const removeStatusMember = (): void => {
  localStorage.removeItem("statusMember");
};
export const removeUserType = (): void => {
  localStorage.removeItem("userType");
};
export const removeUserRole = (): void => {
  localStorage.removeItem("userRole");
};

export default function logOut() {
  removeToken();
  removeStatusMember();
  removeUserRole();
  deleteFromLocalStorageUser("user");
  removeUserType();
  useAuthStore.getState().resetAuthentication();
}

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
}

export const calculateAge = (birthDate: string) => {
  const birthMoment = moment(birthDate, "YYYY-MM-DD").format("DD/MM/YYYY");
  const birthConvert = moment(birthMoment, "DD/MM/YYYY", true);

  // if (!birthConvert.isValid()) {
  //   console.error('Ошибка: Некорректный формат даты рождения')
  //   return null
  // }
  const age = moment().diff(birthConvert, "years");

  return age.toString();
};

export interface IUserLSorage {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  subscribe_status: string;
  type: string;
  speciality: string;
  subscribe_expire_date: string;
  role: string;
  [key: string]: any;
}

export function saveToLocalStorageUser(key: string, value: IUserLSorage): void {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocalStorageUser(key: string): IUserLSorage {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

export function deleteFromLocalStorageUser(key: string): void {
  localStorage.removeItem(key);
}

export const convertDate = (inputDate: moment.MomentInput) => {
  const dateFormats = [
    "DDMMYYYY",
    "DD/MM/YY",
    "D/M/YY",
    "DD/M/YY",
    "D/MM/YY",
    "D/M/YYYY",
    "DD/M/YYYY",
    "D/MM/YYYY",
    "DD-XXX-YYYY",
    "DD-MMMM-YYYY",
    "DD-XXX-YY",
    "DD-MMMM-YY",
  ];

  let parsedDate = null;

  for (const format of dateFormats) {
    const parsed = moment(inputDate, format, true);
    if (parsed.isValid()) {
      parsedDate = parsed;
      break;
    }
  }

  if (!parsedDate) {
    return;
  }

  const formattedDate = parsedDate.format("YYYY-MM-DD");

  return formattedDate;
};
