import TextEditor from "components/TextEditor/TextEditor";

function EditPrivacyPolicies() {
  return (
    <>
      <TextEditor title="Privacy Policy" route="privacy-policy" />
    </>
  );
}

export default EditPrivacyPolicies;
