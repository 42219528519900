import { create } from 'zustand';

interface AuthState {
  accessToken: string | null;
  phone: string | "";

  isAuthenticated: boolean;
  setAuthData: (data: { accessToken: string; phone: string}) => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
  resetAuthentication: () => void; 
 
}

const useAuthStore = create<AuthState>((set) => ({
  accessToken: null,
  phone: "",

  isAuthenticated: false,
  setAuthData: (data) => set(data),
  setAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
  resetAuthentication: () => set({ isAuthenticated: false }),
}));

export default useAuthStore;
