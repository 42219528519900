import Modal from "components/Modal/Modal";
import UserTypeModal from "components/Modal/UserType";
import { ReactNode } from "react";

interface IProfileUserType {
  title: string;
  icon?: ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
  settingsProfile?: () => void;
}

export default function ProfileUserType({
  isOpen,
  onRequestClose,
  title,
  settingsProfile,
  icon,
}: IProfileUserType) {
  return (
    <Modal
      icon={icon}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      children={
        <UserTypeModal
          settingsProfile={settingsProfile}
          onRequestClose={onRequestClose}
        />
      }
      title={title}
    ></Modal>
  );
}
