import React from "react";
import { PageWrapper, StyledH3 } from "styles/components";
function ErrorPage() {
  const message = "Something went wrong!";
  return (
    <PageWrapper>
      <StyledH3>{message}</StyledH3>
    </PageWrapper>
  );
}

export default ErrorPage;
