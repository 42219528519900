import ButtonBack from "components/Buttons/BackButton";
import MainLayout from "layouts/MainLayout";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import OperaionNotes from "./OperationNotes";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  height: 100%;
  background-color: ${baseTheme.colors.white};
  padding-bottom: 10px;
  justify-content: flex-start;
`;

function OperationNotesPages() {
  return (
    <MainLayout
      children={
        <Wrapper>
          <ButtonBack />
          <OperaionNotes />
        </Wrapper>
      }
    />
  );
}

export default OperationNotesPages;
