import React, { ChangeEvent, useMemo, useState } from "react";
import {
  StyleButtonWrapper,
  StyledResetForm,
  StyledRow,
  StyledSpanText,
  StyledResetFormText,
} from "./styles";
import Input from "components/Inputs/Input";
import { baseTheme } from "styles/theme";
import Button from "components/Buttons/Button";
import { useAxios } from "hooks/useAxios";
import "react-toastify/dist/ReactToastify.css";
import Toast from "components/Toast";
import InformModal from "components/Modal/ConfirmModal";
import MailOutlined from "@ant-design/icons/MailOutlined";

function ResetPasswordForm() {
  const { request, loading, error } = useAxios();
  const [email, setEmail] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string>("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let error = "";
    if (!/\S+@\S+\.\S+/.test(value)) {
      error = "Invalid email address";
    }
    setErrorEmail(error);

    setEmail(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const res = await request("auth/forgot-password", "POST", {
        email: email,
      });
      if ((res.message = "Forgot password request sended.")) {
        setEmail("");
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 1500);
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };

  const loginDisabled = useMemo(() => {
    return !email || errorEmail !== "";
  }, [email, errorEmail]);

  return (
    <>
      <StyledResetFormText>
        Forgot your password? No problem. Just let us know your email address
        and we will email you a password reset link that will allow you to
        choose a new one.
      </StyledResetFormText>
      <StyledResetForm onSubmit={handleSubmit}>
        <Input
          type="email"
          error={errorEmail}
          name="email"
          required={true}
          value={email}
          onChange={handleInputChange}
        />
        <StyleButtonWrapper>
          <Button
            loading={loading}
            disabled={loginDisabled}
            fontSize="14px"
            text="Email Password Reset Link"
          />
        </StyleButtonWrapper>
      </StyledResetForm>
      <StyledRow>
        <StyledSpanText>or</StyledSpanText>
        <Button
          fontWeight={700}
          fontSize="14px"
          text="Login"
          color={baseTheme.colors.black}
          mode="/login"
          as="a"
        />
      </StyledRow>
      {error && <Toast error={error} />}
      <InformModal
        isOpen={showModal}
        icon={
          <MailOutlined
            style={{ fontSize: "36px", color: baseTheme.colors.main }}
          />
        }
        title={
          "The password reset link has been successfully sent to your email"
        }
      />
    </>
  );
}

export default ResetPasswordForm;
