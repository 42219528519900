import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

const SkeletonTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const SkeletonTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const SkeletonRow = styled.tr``;

const SkeletonCell = styled.td`
  padding: 12px;
  text-align: left;
`;

interface ISkeletonTableComponent {
  numberOfColumns?: number;
  numberOfRows?: number;
}

function SkeletonTableComponent({
  numberOfColumns = 2,
  numberOfRows = 6,
}: ISkeletonTableComponent) {
  const renderSkeletonColumns = () => {
    return Array.from({ length: numberOfColumns }).map((_, index) => (
      <SkeletonCell key={index}>
        <Skeleton width={80} height={20} />
      </SkeletonCell>
    ));
  };

  const renderSkeletonRows = () => {
    return Array.from({ length: numberOfRows }).map((_, rowIndex) => (
      <SkeletonRow key={rowIndex}>{renderSkeletonColumns()}</SkeletonRow>
    ));
  };

  return (
    <SkeletonTableWrapper>
      <SkeletonTable>
        <thead>
          <SkeletonRow>{renderSkeletonColumns()}</SkeletonRow>
        </thead>
        <tbody>{renderSkeletonRows()}</tbody>
      </SkeletonTable>
    </SkeletonTableWrapper>
  );
}

export default SkeletonTableComponent;
