import styled from "styled-components";
import mobile from "../../assets/img/mobile.png";
import table from "../../assets/img/hero.png";
const HeroWrapper = styled.section`
  background-image: linear-gradient(to bottom right, #199a8e, black);
  overflow: hidden;
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: bottom;
  background-position-y: bottom;
  background-position-x: -50px;
  @media (min-width: 1921px) {
    height: 80vh;
  }
  @media (min-width: 2500px) {
    height: 75vh;
  }
`;

const HeroContainer = styled.div`
  max-width: 1140px;
  display: flex;
  margin-left: auto;
  padding-top: 100px;
  margin-right: auto;
  @media (max-width: 991px) {
    padding-top: 20px;
  }
  @media (min-width: 1921px) {
    max-width: 1440px;
  }
`;

const HeroTitle = styled.h2`
  font-size: 45px;
  line-height: 61px;
  color: #fff;
  font-weight: 600;
  @media (max-width: 991px) {
    font-size: 40px;
    text-align: center;
  }
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const TrialText = styled.p`
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 600;
  color: white;
  padding-top: 10px;
  width: 100%;
  line-height: 40px;
  border: none;
  border-radius: 5px;
  @media (max-width: 991px) {
    text-align: center;
  }
`;

const HeroText = styled.p`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 40px;
  padding-top: 10px;
  color: #fff;
  font-weight: 400;
  @media (max-width: 991px) {
    text-align: center;
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
`;

const TrySnapLog = styled.p`
  width: 100%;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0;
  padding-top: 10px;
  line-height: 40px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  @media (max-width: 991px) {
    text-align: center;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
`;

const HeroContainerText = styled.div`
  flex: 0 0 50%;
  padding-top: 50px;
  height: 100%;
  @media (max-width: 1140px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 700px;
    display: flex;
    padding-top: 0;
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    padding-top: 80px;
  }
`;
const LaptopImageWrap = styled.div`
  width: 850px;
  filter: drop-shadow(5px 5px 50px rgba(216, 215, 215, 0.521));
  @media (min-width: 1921px) {
    padding-left: 25px;
    width: 1100px;
  }
`;
const LaptopImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

const PhoneImageWrap = styled.div`
  position: absolute;
  bottom: -5%;
  left: -8%;
  width: 235px;
  height: auto;
  filter: drop-shadow(5px 5px 20px rgba(161, 161, 161, 0.521));
  z-index: 1;
  @media (min-width: 1921px) {
    left: -2%;
  }
`;

const PhoneImage = styled.img`
  width: 100%;
  height: 100%;
`;

const HeroContainerImages = styled.div`
  position: relative;
  flex: 0 0 50%;

  @media (max-width: 991px) {
    display: none;
  }
`;

function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <HeroContainerText>
          <HeroTitle>
            Snap Log revolutionize the way you log your surgical cases!
          </HeroTitle>
          <HeroText>
            Discover rapid data entry using your smart device camera
          </HeroText>
          <TrialText>Free 7-Day Trial</TrialText>
          <TrySnapLog>TRY SNAP LOG TODAY</TrySnapLog>
        </HeroContainerText>

        <HeroContainerImages>
          <LaptopImageWrap>
            <LaptopImage src={table} data-aos="fade-left" />
          </LaptopImageWrap>
          <PhoneImageWrap>
            <PhoneImage src={mobile} data-aos="fade-left" />
          </PhoneImageWrap>
        </HeroContainerImages>
      </HeroContainer>
    </HeroWrapper>
  );
}

export default Hero;
