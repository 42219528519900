import { create } from 'zustand';
import {IImage} from 'interfaces/index'

interface ReviewCaseImages {
  images: IImage[]; 
  setImages: (payload: { images: IImage[] }) => void; 
}

const useReviewCaseImagesStore = create<ReviewCaseImages>((set) => ({
  images: [], 
  setImages: ({ images }: { images: IImage[] }) => set({ images }),
}));

export default useReviewCaseImagesStore;
