import React from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { IButton } from "interfaces";
import { Link, LinkProps } from "react-router-dom";
import Spinner from "components/Spinners/ButtonSpinner";

interface IStyledButtonProps extends LinkProps {
  flat?: boolean;
  alignSelf?: string;
  fontSize?: string;
  fontWeight?: number;
  bg?: string;
  br?: string;
  color?: string;
  justify?: string;
  border?: string;
  decoration?: boolean;
  width?: string;
  height?: string;
}

const commonStyles = `
  display: flex;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
`;

const StyledButton = styled.button<IStyledButtonProps>`
  ${commonStyles}
  align-items: center;
  border: none;
  height: 45px;
  border-radius: 49px;
  width: ${(props) => (props.width ? props.width : "100%")};
  font-weight: 600;
  text-align: center;
  text-decoration: ${(props) => (props.decoration ? "underline" : "none")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  border: 1px solid ${(props) => (props.br ? props.br : "none")};
  color: ${(props) => (props.color ? props.color : baseTheme.colors.white)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "15px")};
  background-color: ${(props) => (props.bg ? props.bg : baseTheme.colors.main)};
  &:disabled {
    background-color: rgba(25, 154, 142, 0.3);
    cursor: not-allowed;
    color: ${baseTheme.colors.white};
  }

  @media (min-width: 1921px) {
    height: 58px;
    font-size: 20px;
  }
`;
const shouldForwardProp = (prop: string) => prop !== "justify";
const StyledLink = styled(Link).withConfig({
  shouldForwardProp,
})<IStyledButtonProps>`
  ${commonStyles}
  align-items: center;
  text-align: center;
  border-radius: 49px;
  height: ${(props) => (props.height ? props.height : "45px")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  color: ${(props) => (props.color ? props.color : baseTheme.colors.white)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  text-decoration: ${(props) => (props.decoration ? "underline" : "none")};
  border: ${(props) =>
    props.border ? `1px solid ${baseTheme.colors.main}` : props.border};
  background-color: ${(props) => (props.bg ? props.bg : "transparent")};
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;

const Button = ({
  as = "button",
  fontWeight,
  fontSize,
  text,
  mode,
  color,
  flat,
  bg,
  br,
  width,
  onClick,
  border,
  height,
  disabled,
  alignSelf,
  justify,
  decoration,
  loading,
}: IButton & { as?: "button" | "a" }) => {
  const StyledComponent = as === "button" ? StyledButton : (StyledLink as any);

  return (
    <StyledComponent
      as={as}
      bg={bg}
      decoration={decoration}
      justify={justify}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      br={br}
      height={height}
      width={width}
      onClick={onClick}
      border={border}
      disabled={disabled}
      flat={flat ? "true" : undefined}
      alignSelf={alignSelf}
      href={as === "a" ? (mode as string) : undefined}
    >
      {loading && (
        <Spinner
          color={
            bg === "transparent"
              ? baseTheme.colors.main
              : baseTheme.colors.white
          }
        />
      )}
      {!loading && text}
    </StyledComponent>
  );
};

export default Button;
