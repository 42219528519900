import React, { useEffect } from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const FooterNavStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const LinkStyled = styled.a`
  color: ${baseTheme.colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 5px;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 29px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
  &:hover {
    color: #ded9c3;
  }
`;
function FooterNav() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <FooterNavStyled>
      <LinkStyled href="#home">Home</LinkStyled>
      <LinkStyled href="#about">About</LinkStyled>
      <LinkStyled href="#pricing">Pricing</LinkStyled>
      <LinkStyled href="#faq">FAQ</LinkStyled>
      <LinkStyled href="/login">Login</LinkStyled>
      <LinkStyled href="/register">Sign up</LinkStyled>
    </FooterNavStyled>
  );
}

export default FooterNav;
