import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { baseTheme } from 'styles/theme';

export const SidebarContainer = styled.div`
  width: 225px;
  background-color: ${baseTheme.colors.white};
  color: #fff;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const TitleWrap = styled.div`

`
export const Title = styled.h3`
  padding-top: 5px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${baseTheme.colors.main};
  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Tab = styled.div`
  position: relative;
  padding: 10px 0;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.3s ease;
    width: 175px;
    font-family: "Poppins", sans-serif;
    background-color: transparent;
    border: none;
    color: ${baseTheme.colors.black};
    cursor: pointer;
    padding: 8px;
    font-weight: 500;
    font-size: 1rem;

    &:hover {
      /* background-color: rgba(0, 128, 0, 0.1); */
    }
  }

  &.active {
    span {
      color: ${baseTheme.colors.main};
      /* background-color: rgba(0, 128, 0, 0.1); */
    }
  }
  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.active .icon {
    opacity: 1;
  }
`;
export const ProfileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.3s ease;
  width: 175px;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  color: ${baseTheme.colors.black};
  cursor: pointer;
  padding: 8px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 1rem;
`;

export const LogoutText = styled.p`
  line-height: 1;
  font-size: 1rem;
  padding-right: 5px;
  font-weight: 600;
  color: ${baseTheme.colors.main};
  @media (max-width: 500px) {
    font-size: 0.7rem;
  }
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
export const Column = styled.div`
  display: flex;
  padding-top: 10px;
  align-items: center;
  flex-direction: column;
`;
export const ButtonLogout = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const MenuContainer = styled.div`
  width: 225px;
  background-color: ${baseTheme.colors.white};
  color: #fff;
  margin-top: 10px;
  padding: 60px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 690px) {
    display: none;
  }
`;

export const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 15px 0;
  margin-bottom: 10px;
  text-decoration: none;
  position: relative;

  padding-left: 10px;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 600;
  color: ${baseTheme.colors.textGray};
  &.active {
    color: ${baseTheme.colors.main};
  }
  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Подсветка на всю ширину */
    height: 100%;
    background-color: rgba(0, 128, 0, 0.1);
    z-index: -1; /* Чтобы подложка оставалась под элементом */
  }
`;

export const TextButton = styled.span`
  padding-left: 10px;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;