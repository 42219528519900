import React from "react";
import TwoStepForm from "components/Auth/TwoStepForm";
import AuthenticationLayout from "layouts/AuthLayout";

function TwoStepPage() {
  return (
    <AuthenticationLayout>
      <TwoStepForm />
    </AuthenticationLayout>
  );
}

export default TwoStepPage;
