import { baseTheme } from "styles/theme";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Payment from "./Payment";
import { useEffect } from "react";

import { getToken, saveStatusMember } from "helpers";
import { useAxios } from "hooks/useAxios";

function PaymentSuccess() {
  const { request } = useAxios();
  const token = getToken();
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_PAYMENT_ROUTE}`;
    }, 1000);
    // eslint-disable-next-line
  }, []);
  async function getUserInfo() {
    const res = await request("profile/user", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    saveStatusMember(res.data.subscribe_status);
  }
  return (
    <Payment
      color={baseTheme.colors.main}
      text={"Payment Successful!"}
      icon={
        <CheckOutlined
          style={{ fontSize: "100px", color: baseTheme.colors.main }}
        />
      }
    />
  );
}

export default PaymentSuccess;
