import DataComponent from "./DataComponent";

function PathologiesСhecked() {
  return (
    <DataComponent
      uri={"operation-case/pathology/checked?search="}
      title={"Checked Pathology"}
      pathButtonTitle="Pending Pathology"
      pathButtonMode="/pathologies-pending"
      activeTab={"Pathologies"}
    />
  );
}

export default PathologiesСhecked;
