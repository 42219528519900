import React, { useEffect } from "react";
import Payment from "./Payment";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { baseTheme } from "styles/theme";

function PaymentCanceled() {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_PAYMENT_ROUTE}`;
    }, 1000);
    // eslint-disable-next-line
  }, []);
  return (
    <Payment
      color={baseTheme.colors.error}
      text={"Payment Failed!"}
      icon={
        <CloseOutlined
          style={{ fontSize: "100px", color: baseTheme.colors.error }}
        />
      }
    />
  );
}

export default PaymentCanceled;
