import React from "react";
import { useLocation } from "react-router-dom";
import { baseTheme } from "styles/theme";
import Pathology from "assets/icons/Pathology";
import Caselog from "assets/icons/Caselog";
import User from "assets/icons/User";
import Complications from "assets/icons/Complications";
import Reports from "assets/icons/Reports";
import { MenuContainer, MenuItem, TextButton } from "./styles";

function UserMenu() {
  const location = useLocation();
  return (
    <MenuContainer>
      <MenuItem
        className={location.pathname === "/case-logs" ? "active" : ""}
        to="/case-logs"
      >
        <Caselog
          color={
            location.pathname === "/case-logs"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Case Logs</TextButton>
      </MenuItem>
      <MenuItem
        className={location.pathname === "/complications" ? "active" : ""}
        to="/complications"
      >
        <Complications
          color={
            location.pathname === "/complications"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Complications</TextButton>
      </MenuItem>
      <MenuItem
        className={
          location.pathname === "/pathologies-pending" ||
          location.pathname === "/pathologies-checked"
            ? "active"
            : ""
        }
        to={"/pathologies-pending"}
      >
        <Pathology
          color={
            location.pathname === "/pathologies-pending" ||
            location.pathname === "/pathologies-checked"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Pathology</TextButton>
      </MenuItem>
      <MenuItem
        className={location.pathname === "/reports" ? "active" : ""}
        to="/reports"
      >
        <Reports
          color={
            location.pathname === "/reports"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Reports</TextButton>
      </MenuItem>
      <MenuItem
        className={location.pathname === "/profile" ? "active" : ""}
        to="/profile"
      >
        <User
          color={
            location.pathname === "/profile"
              ? baseTheme.colors.main
              : baseTheme.colors.textGray
          }
        />
        <TextButton>Profile</TextButton>
      </MenuItem>
    </MenuContainer>
  );
}

export default UserMenu;
