import Button from "components/Buttons/Button";
import styled from "styled-components";

interface IImagePreview {
  previews: string[];
  addPhotos: () => void;
  loading: boolean;
}

const ImagePreviewWrapper = styled.div`
  display: flex;
  align-items: start;
  overflow-y: auto;
  height: 650px;
  @media (max-width: 500px) {
    height: 360px;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Img = styled.img`
  padding: 10px 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  @media (max-width: 500px) {
    height: 360px;
  }
`;

const ButtonWrap = styled.div`
  width: 150px;
  margin-top: 10px;
`;

function ImagePreview({ previews, addPhotos, loading }: IImagePreview) {
  return (
    <ImagePreviewWrapper>
      <ImagePreviewContainer>
        {previews.map((preview, index) => (
          <Img key={index} src={preview} alt={`Preview ${index + 1}`} />
        ))}
        <ButtonWrap>
          <Button loading={loading} onClick={addPhotos} text="Save" />
        </ButtonWrap>
      </ImagePreviewContainer>
    </ImagePreviewWrapper>
  );
}

export default ImagePreview;
