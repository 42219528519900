import React, { ChangeEvent, useMemo, useState } from "react";
import { PageWrapper, StyledH3 } from "styles/components";
import styled from "styled-components";
import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import { useNavigate, useParams } from "react-router-dom";
import { useAxios } from "hooks/useAxios";
import { ILoginForm } from "interfaces";
import { IconWrap, StyleButtonWrapper } from "components/Auth/styles";
import Toast from "components/Toast";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { baseTheme } from "styles/theme";
const Form = styled.form`
  width: 300px;
`;

function ChangePassword() {
  const { request, error, loading } = useAxios();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [formData, setFormData] = useState<ILoginForm>({
    email: "",
    password: "",
  });
  const [formDataErrors, setFormDataErrors] = useState<ILoginForm>({
    email: "",
    password: "",
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Invalid email address";
      }
      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        email: error,
      }));
    }
    if (name === "password") {
      if (value.length < 8) {
        error = "Password must contain at least 8 characters";
      } else if (!/\d/.test(value)) {
        error = "Password must contain one digit";
      } else if (!/[A-Z]/.test(value)) {
        error = "Password must contain at least one uppercase letter";
      } else if (!/[@#$%^&*()_+!]/.test(value)) {
        error = "Password must contain one special character";
      }

      setFormDataErrors((prevFormDataErrors) => ({
        ...prevFormDataErrors,
        password: error,
      }));
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const res = await request("auth/forgot-password/check", "POST", {
        email: formData.email,
        password: formData.password,
        token: token,
      });

      if (res) {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };
  const buttonDisabled = useMemo(() => {
    return (
      !formData.email ||
      !formData.password ||
      formDataErrors.email !== "" ||
      formDataErrors.password !== ""
    );
  }, [formData, formDataErrors]);
  return (
    <>
      <PageWrapper>
        <StyledH3>Change your password</StyledH3>
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder="Email"
            type="email"
            error={formDataErrors.email}
            name="email"
            required={true}
            value={formData.email}
            onChange={handleInputChange}
          />
          <Input
            name="password"
            type={showPassword ? "text" : "password"}
            error={formDataErrors.password}
            required={true}
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            icon={
              <IconWrap onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <EyeOutlined
                    style={{
                      color: baseTheme.colors.textGray,
                      fontSize: "18px",
                    }}
                  />
                ) : (
                  <EyeInvisibleOutlined
                    style={{
                      color: baseTheme.colors.textGray,
                      fontSize: "18px",
                    }}
                  />
                )}
              </IconWrap>
            }
          />
          <StyleButtonWrapper>
            <Button disabled={buttonDisabled} loading={loading} text="Save" />
          </StyleButtonWrapper>
        </Form>
      </PageWrapper>
      {error && <Toast error={error} />}
    </>
  );
}

export default ChangePassword;
