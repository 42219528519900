import { useEffect, useState } from "react";
import { styled } from "styled-components";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import Select from "components/Select";
import Toast from "components/Toast";
import EditIcon from "assets/icons/EditIcon";
import { ChangeEvent } from "react";
import { useAxios } from "hooks/useAxios";
import { convertDate, formatDate, getToken } from "helpers";
import setList from "data/set";
import supervisionLevel from "data/supervisionLevel";
import rotations from "data/rotation";
import { TitleSection } from "./InfoLog";
import {
  ButtonEdit,
  Column,
  ColumnEdit,
  DFlex,
  StyledText,
  Title,
  TitleSectionContainer,
} from "./styles";
import { ICaseLog, ISettings } from "interfaces";
import AutoCompleteInput, { Supervisor } from "components/AutoCompleteInput ";

const OperationDetailsStyles = styled.div``;
const SCButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

interface IOperationDetails {
  log: ICaseLog;
  getLog: () => void;
  opearationCaseFields: ISettings;
}

interface EditState {
  [key: string]: boolean;
}

interface OperationData {
  [key: string]: string;
}

const EditInput = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  border-bottom: 1px solid ${baseTheme.colors.main};
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
function OperationDetails({
  log,
  getLog,
  opearationCaseFields,
}: IOperationDetails) {
  const { request, error, clearError, loading } = useAxios();
  const date = log?.date && formatDate(log.date);
  const [allSupervisors, setAllSupervisors] = useState<Supervisor[]>([]);
  const supervisor: any = log?.supervisor?.length && log.supervisor?.[0];
  const hospital: any = log?.hospital?.[0];

  const [hospitals, setHospitals] = useState([]);
  const token = getToken();
  useEffect(() => {
    getAllSupervisors();
    getHospitalsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getHospitalsList(): Promise<void> {
    const res = await request("profile/hospital/list", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setHospitals(res.data);
  }

  const getAllSupervisors = async () => {
    const settings = await request("profile/supervisor", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setAllSupervisors(settings?.data || []);
  };
  const [operationData, setOperationData] = useState<OperationData>({
    operation_name: log?.operation_name || "N/A",
    date: date || "N/A",
    mbs_code: log?.mbs_code || "N/A",
    pgy: log?.pgy || "N/A",
    set: log?.set || "N/A",
    urgency: log?.urgency || "N/A",
    surgeon_operated_with: log?.surgeon_operated_with || "N/A",
    rotation: log?.rotation || "N/A",
    supervisor: supervisor?.name || "N/A",
    hospital: hospital?.name || "N/A",
    supervision_level: log?.supervision_level || "N/A",
  });
  const [editState, setEditState] = useState<EditState>({
    operation_name: false,
    mbs_code: false,
    date: false,
    pgy: false,
    set: false,
    surgeon_operated_with: false,
    urgency: false,
    rotation: false,
    supervisor: false,
    supervision_level: false,
    hospital: false,
  });

  const toggleEdit = (field: string) => {
    setEditState((prevState) => ({
      ...prevState,
      [field]: !prevState[field as keyof typeof prevState],
    }));
  };

  const onSaveOperationDetails = async (field: keyof ICaseLog) => {
    let updatedValue: string | undefined = operationData[field];

    if (field === "date") {
      updatedValue = convertDate(updatedValue);
    }

    const updatedData: Partial<OperationData> = {
      [field]: updatedValue,
    };

    if (field === "supervisor") {
      clearError();
      const existingSupervisor = allSupervisors.find(
        (sup) => sup.name.toLowerCase() === updatedValue?.toLowerCase()
      );

      let supervisorId;
      if (existingSupervisor) {
        supervisorId = existingSupervisor.id;
      } else {
        const res = await request(
          "profile/supervisor",
          "POST",
          { name: updatedValue },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        if (res?.data) {
          supervisorId = res.data.id;
        }
      }

      if (supervisorId) {
        const res = await request(
          `operation-case/${log.id}`,
          "POST",
          { supervisor_id: supervisorId },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        if (res) {
          getLog();
        }
      }
    } else if (field === "hospital") {
      const hospitalId: any = hospitals.find(
        (sup: Supervisor) =>
          sup.name.toLowerCase() === updatedValue?.toLowerCase()
      );

      if (hospitalId) {
        console.log("existingSupervisor", {
          hospital_id: hospitalId.id,
        });
        const res = await request(
          `operation-case/${log.id}`,
          "POST",
          { hospital_list_id: hospitalId.id },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        console.log("res", res);
        if (res) {
          getLog();
        }
      }
    } else {
      const res = await request(
        `operation-case/${log.id}`,
        "POST",
        updatedData,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (res) {
        getLog();
      }

      setEditState((prevState) => ({
        ...prevState,
        [field]: false,
      }));
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOperationData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleFieldChange = (field: string, value: string) => {
    setOperationData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const renderField = (
    field: keyof ICaseLog,
    label: string,
    value: string,
    edit?: boolean,
    dropdown?: boolean
  ) => {
    let options: any = [];
    switch (field) {
      case "rotation":
        options = rotations;
        break;
      case "supervision_level":
        options = supervisionLevel;
        break;
      case "set":
        options = setList;
        break;
      default:
        options = [];
        break;
    }

    return edit ? (
      <ColumnEdit key={field}>
        <Title>{label}:</Title>
        {dropdown ? (
          <Select
            isOpDetails={true}
            options={options}
            onChange={(value: string | number) =>
              setOperationData((prevFormData) => ({
                ...prevFormData,
                [field]: value.toString(),
              }))
            }
          />
        ) : field === "supervisor" ? (
          <AutoCompleteInput
            allSupervisors={allSupervisors}
            value={operationData[field]}
            onChange={(value) => handleFieldChange(field, value)}
          />
        ) : field === "hospital" ? (
          <AutoCompleteInput
            allSupervisors={hospitals}
            value={operationData[field]}
            onChange={(value) => handleFieldChange(field, value)}
          />
        ) : (
          <EditInput
            type="text"
            name={field}
            required={true}
            value={operationData[field]}
            onChange={handleInputChange}
          />
        )}
        <SCButtons>
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Save"
            as="button"
            bg="transparent"
            loading={loading}
            onClick={() => onSaveOperationDetails(field)}
            color={baseTheme.colors.black}
          />
          <Button
            fontWeight={500}
            fontSize="0.8rem"
            text="Cancel"
            as="button"
            bg="transparent"
            onClick={() => toggleEdit(field)}
            color={baseTheme.colors.black}
          />
        </SCButtons>
      </ColumnEdit>
    ) : (
      <Column key={field}>
        <Title>{label}:</Title>
        <StyledText>{value || "N/A"}</StyledText>
        <ButtonEdit onClick={() => toggleEdit(field)}>
          <EditIcon width={20} height={20} color={baseTheme.colors.textGray} />
        </ButtonEdit>
      </Column>
    );
  };
  return (
    <>
      <OperationDetailsStyles>
        <TitleSectionContainer>
          <DFlex>
            <TitleSection>Operations details</TitleSection>
          </DFlex>
        </TitleSectionContainer>
        {renderField(
          "operation_name",
          "Operation",
          log?.operation_name || "N/A",
          editState.operation_name
        )}
        {opearationCaseFields?.date &&
          renderField("date", "Date", date || "N/A", editState.date)}
        {opearationCaseFields?.mbs_code &&
          renderField(
            "mbs_code",
            "MBS",
            log?.mbs_code || "N/A",
            editState.mbs_code
          )}
        {opearationCaseFields?.urgency &&
          renderField(
            "urgency",
            "Urgency",
            log?.urgency || "N/A",
            editState.urgency
          )}
        {opearationCaseFields?.hospital_list_id &&
          renderField(
            "hospital",
            "Hospital",
            hospital?.name || "N/A",
            editState.hospital
          )}

        {opearationCaseFields?.rotation &&
          renderField(
            "rotation",
            "Rotation",
            log?.rotation || "N/A",
            editState.rotation,
            true
          )}
        {opearationCaseFields?.supervisor_id &&
          renderField(
            "supervisor",
            "Supervisor",
            supervisor?.name || "N/A",
            editState.supervisor
          )}
        {opearationCaseFields?.supervision_level &&
          renderField(
            "supervision_level",
            "Supervison level",
            log?.supervision_level || "N/A",
            editState.supervision_level,
            true
          )}
        {opearationCaseFields?.pgy &&
          renderField("pgy", "PGY", log?.pgy || "N/A", editState.pgy)}
        {opearationCaseFields?.set &&
          renderField("set", "SET", log?.set || "N/A", editState.set, true)}

        {opearationCaseFields?.surgeon_operated_with &&
          renderField(
            "surgeon_operated_with",
            "Surgeon operated with",
            log?.surgeon_operated_with || "N/A",
            editState.surgeon_operated_with
          )}
      </OperationDetailsStyles>
      {error && <Toast error={error} />}
    </>
  );
}

export default OperationDetails;
