import CheckOutlined from "@ant-design/icons/CheckOutlined";
import Button from "components/Buttons/Button";
import Input from "components/Inputs/Input";
import InformModal from "components/Modal/ConfirmModal";
import Modal from "components/Modal/Modal";
import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { ReactNode, useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import { TitleSection } from "styles/components";
import { baseTheme } from "styles/theme";
import { WrapSpinner } from "./SetUrgency";
import Spinner from "components/Spinners/ButtonSpinner";

const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

interface ISetPgy {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
  settingsProfile?: any;
  loading: boolean;
  data?: any;
  label: string;
  icon?: ReactNode;
}
const Form = styled.form`
  width: 100%;
  padding-top: 10px;
`;
export const DefultSpan = styled.span`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
`;
interface IPgyForm {
  settingsProfile: () => void;
  data: any;
  label: string;
}

const PgyForm = ({ data, label }: IPgyForm) => {
  const [defaultPgy, setDefaultPgy] = useState();
  useEffect(() => {
    settingsProfile();
    // eslint-disable-next-line
  }, []);
  const token = getToken();
  const [pgy, setPgy] = useState<string>("");
  const [showModalInternal, setShowModalInternal] = useState(false);
  const { request, error } = useAxios();
  const [errorPgy] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPgy(inputValue);
  };
  async function settingsProfile() {
    setLoading(true);
    const res = await request("profile/operation-case/settings", "GET", null, {
      Authorization: `Bearer ${token}`,
    });

    setDefaultPgy(res.data?.[0]?.presets.pgy);
    setLoading(false);
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          pgy: pgy,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if ((res.message = "Updated")) {
      setShowModalInternal(true);
      setPgy("");

      setTimeout(() => {
        settingsProfile();
        setShowModalInternal(false);
      }, 1000);
    }
  }
  const buttonDisabled = useMemo(() => {
    return !pgy;
  }, [pgy]);
  if (loading) {
    return (
      <WrapSpinner>
        <Spinner size={40} />
      </WrapSpinner>
    );
  }
  return (
    <>
      <>
        <TitleSection>
          {defaultPgy ? (
            <>
              Your current PGY defaults <DefultSpan>{defaultPgy}</DefultSpan>.
              If you want to change the PGY, enter a new value from 1 to 15
            </>
          ) : (
            <>Enter PGY from 1 to 15</>
          )}
        </TitleSection>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            error={errorPgy}
            name="pgy"
            required={true}
            placeholder={"Enter PGY"}
            value={pgy}
            onChange={handleInputChange}
          />
          <ButtonWrapper>
            <Button
              fontWeight={600}
              color={baseTheme.colors.white}
              fontSize="14px"
              disabled={buttonDisabled}
              text="Save"
            />
          </ButtonWrapper>
        </Form>
      </>

      <InformModal
        isOpen={showModalInternal}
        title={"Pgy changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
      {error && <Toast error={error} />}
    </>
  );
};

export default function SetPgy({
  isOpen,
  onRequestClose,
  settingsProfile,
  title,
  label,
  data,
  icon,
}: ISetPgy) {
  return (
    <Modal
      icon={icon}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      children={
        <PgyForm settingsProfile={settingsProfile} data={data} label={label} />
      }
      title={title}
    ></Modal>
  );
}
