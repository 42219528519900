import { IIcon } from "interfaces";

const Pathology = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_242_60)">
          <path
            d="M19.7778 12C19.7778 16.2955 16.2955 19.7778 12 19.7778M19.7778 12C19.7778 7.70448 16.2955 4.22219 12 4.22219M19.7778 12H22M12 19.7778C7.70448 19.7778 4.22219 16.2955 4.22219 12M12 19.7778V22M12 4.22219C7.70448 4.22219 4.22219 7.70448 4.22219 12M12 4.22219V2M4.22219 12H2M17.4997 6.50031L19.071 4.92896M4.92896 19.0711L6.50031 17.4998M6.50031 6.50031L4.92896 4.92896M19.0711 19.0711L17.4998 17.4998M16.4445 13.6667C16.4445 14.5871 15.6982 15.3333 14.7778 15.3333C13.8573 15.3333 13.1111 14.5871 13.1111 13.6667C13.1111 12.7463 13.8573 12 14.7778 12C15.6982 12 16.4445 12.7463 16.4445 13.6667ZM10.8889 10.3333C10.8889 11.2538 10.1427 12 9.22219 12C8.30177 12 7.55552 11.2538 7.55552 10.3333C7.55552 9.41281 8.30177 8.66667 9.22219 8.66667C10.1427 8.66667 10.8889 9.41281 10.8889 10.3333Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_242_60">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Pathology;
