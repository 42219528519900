import { IIcon } from "interfaces";

const Users = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <circle fill={color} cx="4" cy="10" r="2" />
      <path
        fill={color}
        d="M6.67 13.4A4.19 4.19 0 0 0 5 16.75V17H.75c-.41 0-.75-.34-.75-.75v-.5C0 14.23 1.23 13 2.75 13h2.5c.52 0 1.01.15 1.42.4z"
      />
      <circle fill={color} cx="20" cy="10" r="2" />
      <path
        fill={color}
        d="M24 15.75v.5c0 .41-.34.75-.75.75H19v-.25c0-1.37-.66-2.59-1.67-3.35.41-.25.9-.4 1.42-.4h2.5c1.52 0 2.75 1.23 2.75 2.75z"
      />
      <circle fill={color} cx="12" cy="9.5" r="3" />
      <path
        fill={color}
        d="M14.75 14h-5.5a2.752 2.752 0 0 0-2.75 2.75v1.5c0 .414.336.75.75.75h9.5a.75.75 0 0 0 .75-.75v-1.5A2.752 2.752 0 0 0 14.75 14z"
      />
    </svg>
  );
};

export default Users;
