import Button from "components/Buttons/Button";
import Modal from "components/Modal/Modal";
import Toast from "components/Toast";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { ReactNode, useEffect, useReducer, useState } from "react";
import InformModal from "components/Modal/ConfirmModal";
import CheckOutlined from "@ant-design/icons/CheckOutlined";

import styled from "styled-components";
import { baseTheme } from "styles/theme";
import Spinner from "components/Spinners/ButtonSpinner";

const ButtonWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
`;

interface ISetUrgency {
  title: string;
  isOpen: boolean;
  onRequestClose: () => void;
  settingsProfile?: any;
  loading: boolean;
  data?: any;
  icon?: ReactNode;
}

interface IUrgencyButtons {
  settingsProfile: () => void;
  data: any;
  toggle?: () => void;
}
export const WrapSpinner = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  justify-content: center;
`;
const UrgencyButtons = ({ data }: IUrgencyButtons) => {
  const token = getToken();
  const [loading, setLoading] = useState(false);
  const [currentUrgency, setCurrentUrgency] = useState();
  const [show, toggle] = useReducer((show) => !show, false);
  useEffect(() => {
    settingsProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { request, error } = useAxios();

  async function settingsProfile() {
    setLoading(true);
    const res = await request("profile/operation-case/settings", "GET", null, {
      Authorization: `Bearer ${token}`,
    });

    setCurrentUrgency(res.data?.[0]?.presets.urgency);
    setLoading(false);
  }
  async function setUrgency(urgency: string) {
    const res = await request(
      "profile/presets",
      "POST",
      {
        presets: {
          urgency: urgency,
        },
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if ((res.message = "Updated")) {
      toggle();
      settingsProfile();

      setTimeout(() => {
        toggle();
      }, 1000);
    }
  }
  if (loading) {
    return (
      <WrapSpinner>
        <Spinner size={40} />
      </WrapSpinner>
    );
  }
  return (
    <>
      <>
        <ButtonWrapper>
          <Button
            as="button"
            onClick={() => setUrgency("Elective")}
            text={"Elective"}
            br={
              currentUrgency === "Elective"
                ? baseTheme.colors.main
                : baseTheme.colors.black
            }
            bg={
              currentUrgency === "Elective"
                ? baseTheme.colors.main
                : "transparent"
            }
            color={
              currentUrgency === "Elective"
                ? baseTheme.colors.white
                : baseTheme.colors.black
            }
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => setUrgency("Emergency")}
            text="Emergency"
            as="button"
            br={
              currentUrgency === "Emergency"
                ? baseTheme.colors.main
                : baseTheme.colors.black
            }
            bg={
              currentUrgency === "Emergency"
                ? baseTheme.colors.main
                : "transparent"
            }
            color={
              currentUrgency === "Emergency"
                ? baseTheme.colors.white
                : baseTheme.colors.black
            }
          />
        </ButtonWrapper>
      </>

      <InformModal
        isOpen={show}
        title={"Urgency changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
      {error && <Toast error={error} />}
    </>
  );
};

export default function SetUrgency({
  isOpen,
  onRequestClose,
  settingsProfile,
  title,
  icon,
  data,
}: ISetUrgency) {
  const [show, toggle] = useReducer((show) => !show, false);
  return (
    <>
      <Modal
        icon={icon}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        children={
          <UrgencyButtons
            toggle={toggle}
            settingsProfile={settingsProfile}
            data={data}
          />
        }
        title={title}
      ></Modal>
      <InformModal
        isOpen={show}
        title={"User type changed"}
        icon={
          <CheckOutlined
            style={{ fontSize: "24px", color: baseTheme.colors.main }}
          />
        }
      />
    </>
  );
}
























































