import React from "react";
import styled from "styled-components";
import { baseTheme } from "styles/theme";

const ToggleSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
`;

const ToggleSwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
  &:checked + span {
    background-color: ${baseTheme.colors.main};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${baseTheme.colors.main};
  }

  &:checked + span:before {
    transform: translateX(13px);
  }
`;

const ToggleSwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 17px;

  &:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ToggleSwitch: React.FC<{ isChecked: boolean; onChange: () => void }> = ({
  isChecked,
  onChange,
}) => {
  return (
    <ToggleSwitchWrapper>
      <ToggleSwitchInput
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <ToggleSwitchSlider />
    </ToggleSwitchWrapper>
  );
};

export default ToggleSwitch;
