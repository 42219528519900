import React, { useState } from "react";
import { IInput } from "interfaces";
import { baseTheme } from "styles/theme";
import styled from "styled-components";

interface StyledInnerWrapProps {
  hasError: boolean;
  isFocused: boolean;
  bg?: string;
  color?: string;
}

const StyledInputWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const StyledErrorWrap = styled.div`
  position: absolute;
  color: ${baseTheme.colors.error};
  font-size: 0.7rem;
  bottom: -30%;
  padding-left: 5px;
`;
const StyledLabel = styled.label`
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
`;
const StyledInnerWrap = styled.div<StyledInnerWrapProps>`
  background-color: ${({ bg }) => (bg ? bg : baseTheme.colors.gray)};
  display: flex;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.75rem;
  border-radius: 49px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition-duration: 0.3s;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? baseTheme.colors.error : baseTheme.colors.gray};

  &:focus-within {
    border-color: ${({ hasError }) =>
      hasError ? baseTheme.colors.error : baseTheme.colors.border};
  }
`;
const IconWrap = styled.div`
  padding-right: 5px;
`;
const StyledInput = styled.input`
  width: 100%;
  outline: none;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  border-color: transparent;
  line-height: 1.4;
  line-height: 1;
  font-size: 14px;
  border: none;
  &::placeholder {
    color: ${({ color }) => (color ? color : baseTheme.colors.textGray)};
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
`;

const Input = ({
  onChange,
  type,
  name,
  error,
  placeholder,
  label,
  maxLength,
  value,
  required,
  title,
  bg,
  color,
  pattern,
  readOnly,
  icon,
  autoComplete,
}: IInput) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <StyledInputWrap>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInnerWrap
        hasError={!!error}
        bg={bg}
        isFocused={isFocused}
        className={isFocused ? "focused" : ""}
      >
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          pattern={pattern}
          maxLength={maxLength}
          title={title}
          color={color}
          autoComplete={autoComplete}
          required={required}
          readOnly={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {icon && <IconWrap>{icon}</IconWrap>}
      </StyledInnerWrap>
      {error && <StyledErrorWrap>{error}</StyledErrorWrap>}
    </StyledInputWrap>
  );
};

export default Input;
