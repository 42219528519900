import Select from "components/Select";
import styled from "styled-components";
import { StyledH3 } from "styles/components";
import Rotation from "data/rotation";
const SpecialityContainer = styled.div``;
interface ISpeciality {
  setItem: (value: string | number) => void;
}

function Speciality({ setItem }: ISpeciality) {
  const options: any = Rotation;
  return (
    <SpecialityContainer>
      <StyledH3>What is your current specialty rotation?</StyledH3>
      <Select
        options={options}
        onChange={(value: string | number) => setItem(value.toString())}
      />
    </SpecialityContainer>
  );
}

export default Speciality;
