import { IIcon } from "interfaces";

const Complications = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_242_73)">
          <path
            d="M3 17H21M7.00003 10.0001H9L10 12L12 7.00006L14 14L15 12H17M4.99997 20H19C20.1046 20 21 19.1046 21 18V6.00003C21 4.89547 20.1046 4.00006 19 4.00006H4.99997C3.89541 4.00006 3 4.89547 3 6.00003V18C3 19.1046 3.89541 20 4.99997 20Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_242_73">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Complications;
