import styled from "styled-components";
import Select from "components/Select";
import Button from "components/Buttons/Button";
import { IOutcome } from "interfaces";
import { useAxios } from "hooks/useAxios";
import { useState } from "react";
import { getToken } from "helpers";

const OutcomesContent = styled.div`
  padding: 10px;
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 450px;
`;

const ButtonWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
  width: 100%;
`;
interface IOutcomes {
  title?: string;
  options: IOutcome[];
  outcomeLoading?: boolean;
  id?: string;
  queryFieldName: string;
  currentOutcomes: IOutcome[];
  getUpdateLog: () => void;
}

function OutcomeModal({
  options,
  outcomeLoading,
  id,
  currentOutcomes,
  queryFieldName,
  getUpdateLog,
}: IOutcomes) {
  const token = getToken();
  const [item, setItem] = useState<number>();

  const { request } = useAxios();

  async function addOutcome() {
    if (!item) {
      return;
    }
    const formData = new FormData();
    const idsOutcomes: number[] = currentOutcomes.map((outcome) => outcome.id);
    idsOutcomes.push(item);
    idsOutcomes.forEach((id: number | string, index: number) => {
      formData.append(`${queryFieldName}[${index}]`, id.toString());
    });
    try {
      await request(`operation-case/${id}`, "POST", formData, {
        Authorization: ` Bearer ${token}`,
      });
    } catch (e) {}
    getUpdateLog();
  }

  return (
    <OutcomesContent>
      <Select
        options={options.map((option: any) => ({
          value: option.id,
          label: option.name,
        }))}
        onChange={(value: number) => setItem(value)}
      />
      <ButtonWrapper>
        <Button loading={outcomeLoading} onClick={addOutcome} text="Save" />
      </ButtonWrapper>
    </OutcomesContent>
  );
}

export default OutcomeModal;
