import Users from "components/Users";
import { styled } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

function SubscriptionReports() {
  const columns = [
    { Header: "Identifier", accessor: "id" },
    { Header: "First Name", accessor: "first_name" },
    { Header: "Last Name", accessor: "last_name" },
    { Header: "Email Address", accessor: "email" },
    { Header: "Subscribe status", accessor: "subscribe_status" },
    { Header: "Created at", accessor: "created_at" },
    { Header: "Expired at", accessor: "expired_at" },
  ];
  return (
    <Wrapper>
      <Users columns={columns} title="Users" />
    </Wrapper>
  );
}

export default SubscriptionReports;
