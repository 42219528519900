import styled from "styled-components";
import HeaderMenuDesktop from "./HeaderMenuDesktop";
import HeaderMenuMobile from "./HeaderMenuMobile";

const HeaderStyled = styled.header`
  @media (max-width: 991px) {
  }
`;
const HeaderMenuMobileContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
`;
const menuLinks = [
  {
    name: "About",
    to: "#about",
  },
  {
    name: "Pricing",
    to: "#pricing",
  },
  {
    name: "FAQ",
    to: "#faq",
  },
];

function Header() {
  return (
    <HeaderStyled>
      <HeaderMenuDesktop />
      <HeaderMenuMobileContainer>
        <HeaderMenuMobile landing links={menuLinks} />
      </HeaderMenuMobileContainer>
    </HeaderStyled>
  );
}

export default Header;
