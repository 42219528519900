import { useEffect } from "react";
import { Card, IconWrapper, PaymentWrapper } from "./Payment/style";
import MailOutlined from "@ant-design/icons/MailOutlined";
import { baseTheme } from "styles/theme";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

export const StyledH3 = styled.h3`
  font-size: 25px;
  letter-spacing: 1px;
  font-weight: 600;
  color: white;
  padding-top: 10px;
  line-height: 40px;
  color: ${baseTheme.colors.black};
  max-width: 700px;
  text-align: center;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(40, 40, 40, 0.2);
  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

function ConfirmAccount() {
  const navigate = useNavigate();
  useEffect(() => {
    // eslint-disable-next-line
    setTimeout(() => {
      navigate("/login");
    }, 8000);
    // eslint-disable-next-line
  }, []);
  return (
    <PaymentWrapper>
      <Card>
        <IconWrapper>
          <MailOutlined
            style={{ fontSize: "100px", color: baseTheme.colors.main }}
          />
        </IconWrapper>
        <StyledH3>
          An email has been sent to your provided email address. Please verirfy
          your email address to continue.
        </StyledH3>
      </Card>
    </PaymentWrapper>
  );
}

export default ConfirmAccount;
