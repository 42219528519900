export const baseTheme = {
  colors: {
    primary: '#199A8E',
    black: '#222222',
    white: '#FFFFFF',
    error: '#E02B00',
    border: '#7A8CBF',
    gray: '#f2f2f2', 
    link:'#0c14fc',
    textGray:'#b4b4b4',
    textTable: '#5F5F5F',
    main: '#199A8E',
    transparent:'transparent'
  },
  flexDirection: {
    row: 'row',
    column: 'column',
  },
  media: {
    extraLarge: '(max-width: 1140px)',
    large: '(max-width: 960px)',
    medium: '(max-width: 720px)',
    small: '(max-width: 540px)',
  },

  sizes: {
    header: { height: 56 },
    container: { width: 1200 },
    footer: { height: 128 },
    modal: { width: 540 },
    radius: { authCode: 4, input: 8, button: 8 },
  },

  // in ms
  durations: {
    ms300: 300,
  },

  // z-index
  order: {
    header: 50,
    modal: 100,
  },
};

