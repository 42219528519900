import CheckOperationFileds from "components/Modal/CheckOperationFileds";
import Modal from "components/Modal/Modal";
import { ReactNode } from "react";

interface IOpCaseFields {
  title: string;
  isOpen: boolean;
  icon?: ReactNode;
  onRequestClose: () => void;
}

export default function OpCaseFields({
  isOpen,
  onRequestClose,
  title,
  icon,
}: IOpCaseFields) {
  return (
    <Modal
      isOpen={isOpen}
      icon={icon}
      onRequestClose={onRequestClose}
      children={<CheckOperationFileds />}
      title={title}
    ></Modal>
  );
}
