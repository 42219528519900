import { IIcon } from "interfaces";

const SetIcon = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 22.0004C2.99992 20.4608 3.44413 18.9538 4.27935 17.6603C5.11456 16.3669 6.30527 15.3419 7.7086 14.7085C9.11193 14.075 10.6682 13.8599 12.1908 14.0891C13.7133 14.3183 15.1373 14.9819 16.292 16.0004"
          stroke="#199A8E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 19L17 21L21 17"
          stroke="#199A8E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5018 8C14.8173 8.56345 15 9.20306 15 9.87311C15 12.1574 12.9742 14 10.5 14C8.02583 14 6 12.1421 6 9.87311C6 9.20306 6.18266 8.56345 6.49815 8"
          stroke="#199A8E"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 8H6L6.31612 2.68118L7.02273 2.47732C9.25414 1.84722 11.6157 1.84721 13.8471 2.44025L14.6839 2.66266L15 8Z"
          stroke="#199A8E"
          strokeWidth="1.5"
          stroke-miterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default SetIcon;
