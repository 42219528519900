import { StyledSpanText } from "components/Auth/styles";
import Spinner from "components/Spinners/ButtonSpinner";
import Toast from "components/Toast";
import { useAxios } from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledH4 } from "styles/components";
import { baseTheme } from "styles/theme";

const StyledPrivacy = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${baseTheme.colors.gray};
`;

const Container = styled.div`
  border-radius: 20px;
  flex: 1;
  max-width: 1100px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: ${baseTheme.colors.white};
  margin: 50px 10px 10px;

  p {
    margin-bottom: 10px;
    /* font-size: 16px; */
    line-height: 1.5;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    margin-left: 20px;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.5;
  }
`;
function PrivacyPolicy() {
  const { request, error, loading } = useAxios();
  const [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    getPrivacyPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPrivacyPolicy() {
    const res = await request("privacy-policy", "GET", null);
    setPrivacyPolicy(res.value);
  }

  return (
    <>
      <StyledPrivacy>
        <StyledH4>Privacy Policy</StyledH4>
        <br />
        <StyledSpanText>We guarantee your data privacy</StyledSpanText>
        {loading ? (
          <Spinner />
        ) : (
          privacyPolicy && (
            <Container dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
          )
        )}
      </StyledPrivacy>
      {error && <Toast error={error} />}
    </>
  );
}

export default PrivacyPolicy;
