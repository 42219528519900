import { IIcon } from "interfaces";

const Hospital = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_242_70)">
          <path
            d="M13.9999 21V15H10V21M10.5 7.5H12M12 7.5H13.5M12 7.5V9M12 7.5V6M4 6V21H19.9999V6H16.9999V3H7V6H4Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_242_70">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Hospital;
