import { IIcon } from "interfaces";

const Download = ({ color, width = 24, height = 24 }: IIcon) => {
  return (
    <div style={{ width: width, height: height, paddingRight: "5px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="download">
        <path
          fill={color}
          d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z"
        ></path>
      </svg>
    </div>
  );
};

export default Download;
