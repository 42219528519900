import { IIcon } from "interfaces";

const SuspendUser = ({ color, width = 20, height = 20 }: IIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle color={color} cx="12" cy="12" r="10" />
      <path color={color} d="m4.9 4.9 14.2 14.2" />
    </svg>
  );
};

export default SuspendUser;
