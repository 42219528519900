import { StyledSpanText } from "components/Auth/styles";
import Spinner from "components/Spinners/ButtonSpinner";
import { useAxios } from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { StyledH4 } from "styles/components";
import { baseTheme } from "styles/theme";
import Toast from "components/Toast";

const StyledPrivacy = styled.div`
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${baseTheme.colors.gray};
`;

const Container = styled.div`
  border-radius: 20px;
  flex: 1;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  padding: 25px;
  background-color: ${baseTheme.colors.white};
  margin: 50px 10px 10px;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  span {
    font-size: 16px;
    line-height: 1.5;
  }
  strong {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 22px;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }

  li {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.5;
  }
`;
function TermsConditions() {
  const { request, error, loading } = useAxios();
  const [termsAndConditions, setTermsAndConditions] = useState("");

  useEffect(() => {
    getPrivacyPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPrivacyPolicy() {
    const res = await request("terms-and-conditions", "GET", null);
    setTermsAndConditions(res.value);
  }
  return (
    <>
      <StyledPrivacy>
        <StyledH4>Terms and Conditions</StyledH4>
        <br />
        <StyledSpanText>We guarantee your data privacy</StyledSpanText>
        {loading ? (
          <Spinner />
        ) : (
          termsAndConditions && (
            <Container
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            />
          )
        )}
      </StyledPrivacy>
      {error && <Toast error={error} />}
    </>
  );
}

export default TermsConditions;
