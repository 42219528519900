import styled from 'styled-components';
import { baseTheme } from 'styles/theme';

export const StyledSpanColor = styled.span`
  color: ${baseTheme.colors.main};
  font-size: 14px;
  line-height: 20px;

  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
export const IconWrap = styled.div`
  padding-right: 5px;
  cursor: pointer;
`;
export const Form = styled.form``;
export const StyleButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
`;

export const StyledButton = styled.div`
  width: 50%;
  margin-left: auto;
`;
export const StyledDflex = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: flex-start;
`;

export const StyledSpanText = styled.span`
  font-size: 14px;
  color: ${baseTheme.colors.black};
  padding-right: 6px;
  align-items: center;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const StyledBoldText = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-left: 20px;
  line-height: 20px;
  width: 300px;
  @media (min-width: 1921px) {
    width: 400px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const StyledResetFormText = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  @media (min-width: 1921px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const StyledResetForm = styled.form`
  padding-top: 40px;
`;
export const StyledText = styled.span`
  margin-left: 0.5rem;
  color: ${baseTheme.colors.black};
  transition: 0.3s color;
  font-size: 14px;
  line-height: 1rem;
  @media (min-width: 1921px) {
    font-size: 20px;
  }
`;

export const StyledLabel = styled.span`
  font-size: 0.75rem;
  font-weight: bold;
`;

export const ContainerInput = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1em;
`

export const ProfileRow = styled.div`
height: 700px;
`
export const WitdthFull = styled.div`
width: 100%;
`