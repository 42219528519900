import styled from "styled-components";
import Header from "./Header/Header";
import About from "./About";
import Footer from "./Footer/Footer";
import Hero from "./Hero";
import FaqAccordion from "./FAQComponent";
import Pricing from "./Pricing";

const Wrapper = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  position: relative;
  margin: 0 auto;
`;
const faq = [
  {
    question: "Is my data secure?",
    answer:
      "Yes. Your data is encrypted and stored on an Amazon Web Services (AWS) server housed in Australia.",
  },
  {
    question: "Is the app available for Android?",
    answer:
      "Snap Log is available for iOS and Android and is avialable on the App Store Play Store.",
  },
  {
    question: "What happens to my data if I deactivate my account?",
    answer:
      "Your data will be available to download as a CSV file for up to 30 days after your account becomes inactive",
  },
];
function LandingPage() {
  return (
    <Wrapper id="home">
      <Header />
      <Hero />
      <About />
      <Pricing />
      <FaqAccordion faqs={faq} />
      <Footer />
    </Wrapper>
  );
}

export default LandingPage;
