import DataComponent from "./DataComponent";
import { useEffect } from "react";
import Toast from "components/Toast";
import { getToken, saveToLocalStorageUser } from "helpers";
import { useAxios } from "hooks/useAxios";
function CaseLogs() {
  const { request, error } = useAxios();
  const token = getToken();
  async function getUserInfo() {
    const res = await request("profile/user", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    saveToLocalStorageUser("user", res?.data || null);
  }

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DataComponent
        uri={"operation-case?search="}
        title={"Case logs"}
        activeTab={"Case logs"}
      />
      {error && <Toast error={error} />}
    </>
  );
}

export default CaseLogs;
