import styled from "styled-components";
import LogoMain from "../img/logo-main.png";
import LogoBlack from "../img/logo-black.png";
import LogoWhite from "../img/logo-white.png";

const LogoWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: auto;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

interface ILogo {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  flag?: "white" | "black" | "main";
}

function Logo({
  strokeWidth = 4,
  color = "#199A8E",
  width = 45,
  flag = "main",
}: ILogo) {
  let logoSrc;

  switch (flag) {
    case "white":
      logoSrc = LogoWhite;
      break;
    case "black":
      logoSrc = LogoBlack;
      break;
    default:
      logoSrc = LogoMain;
  }

  return (
    <LogoWrapper width={width}>
      <Image src={logoSrc} />
    </LogoWrapper>
  );
}

export default Logo;
