import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "components/PhoneInput/style.css";
interface IPhoneField {
  phone: string;
  setPhone: (value: string) => void;
}

export default function PhoneField({ phone, setPhone }: IPhoneField) {
  return (
    <PhoneInput
      defaultCountry="AU"
      placeholder="Enter phone number"
      value={phone}
      international
      style={{
        "--PhoneInputCountrySelectArrow-color": "#f2f2f2",
      }}
      className="phone-input"
      onChange={setPhone}
    />
  );
}
